export enum ToolName {
  FILTER = 'filter',
  RESIZE = 'resize',
  CROP = 'crop',
  DRAW = 'draw',
  TEXT = 'text',
  SHAPES = 'shapes',
  STICKERS = 'stickers',
  FRAME = 'frame',
  CORNERS = 'corners',
  MERGE = 'merge',
}
